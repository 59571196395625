body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #ffffff;
  color: #000000;
  font-family: "Inter", sans-serif;
}
label {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
iframe {
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.yellowShade {
  position: absolute;
  width: 900px;
  height: 900px;
  left: 50%;
  top: -50%;
  transform: translateX(-50%);
  background: radial-gradient(
    29.3% 29.3% at 55.72% 49.12%,
    #f5c843 0%,
    rgba(245, 200, 67, 0) 100%
  );
  opacity: 0.45;
  filter: blur(28px);
  max-width: 100%;
}

.blueShade {
  position: absolute;
  width: 900px;
  height: 900px;
  left: -20px;
  top: 15vh;
  background: radial-gradient(
    54.45% 64.16% at 38.21% 51.43%,
    #147ec6 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.75;
  border-radius: 50%;
  // backdrop-filter: blur(15px);
  filter: blur(148px);
  max-width: 100%;
}

// .loopTopClassName {
// background: radial-gradient(50.01% 49.98% at 50% 50.02%,
//     #ffffff 18.43%,
//     #fffdf7 18.55%,
//     #fff9e2 18.75%,
//     #fff2be 18.98%,
//     #ffe88e 19.26%,
//     #ffdc4f 19.56%,
//     #ffcd04 19.88%,
//     #ffcc00 19.89%,
//     #ffb900 21.33%,
//     #ff8800 24.23%,
//     #ff7d00 24.86%,
//     #d36800 30.27%,
//     #a25000 37.22%,
//     #773a00 44.55%,
//     #522800 52.13%,
//     #341a00 60.01%,
//     #1d0e00 68.3%,
//     #0d0600 77.18%,
//     #030100 87.07%,
//     #000000 100%);
// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   position: absolute;
//   left: 25%;
//   top: -25px;
//   z-index: 999;
//   height: 24px;
//   max-width: 765px;
//   width: 100%;

//   @media screen and (max-width: 375px) {
//     left: 0%;
//     max-width: 100%;
//   }

//   @media screen and (max-width: 767px) {
//     left: 8%;
//     max-width: 100%;
//   }
// }

// .loopBottomClassName {
// background: radial-gradient(50.01% 49.98% at 50% 50.02%,
//     #ffffff 18.43%,
//     #fffdf7 18.55%,
//     #fff9e2 18.75%,
//     #fff2be 18.98%,
//     #ffe88e 19.26%,
//     #ffdc4f 19.56%,
//     #ffcd04 19.88%,
//     #ffcc00 19.89%,
//     #ffb900 21.33%,
//     #ff8800 24.23%,
//     #ff7d00 24.86%,
//     #d36800 30.27%,
//     #a25000 37.22%,
//     #773a00 44.55%,
//     #522800 52.13%,
//     #341a00 60.01%,
//     #1d0e0014 68.3%,
//     #0700000a 77.18%,
//     #0b000000 87.07%,
//     #0b00000f 100%);
// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   position: absolute;
//   left: 25%;
//   bottom: -18px;
//   z-index: 999;
//   height: 24px;
//   max-width: 765px;
//   width: 100%;

//   @media screen and (max-width: 375px) {
//     left: 0%;
//     max-width: 100%;
//   }

//   @media screen and (max-width: 767px) {
//     left: 8%;
//     max-width: 100%;
//   }
// }

.increaseDecreaseButton {
  background-color: #323232;
  border-radius: 10px;
}

.tableCellContent {
  background: rgba(255, 255, 255, 0.03);
  padding: 8px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.slickbottomslider {
  .slick-initialized .slick-slide.slick-current {
    .active {
      transform: scale(1.15);
      transition: all 0.4s ease-in;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }

  .slick-track {
    padding: 60px 0;
  }

  .slick-prev:before {
    content: "‹";
  }

  .slick-next:before {
    content: "›";
  }

  .slick-prev {
    left: auto !important;
    bottom: -20px !important;
    top: -28px !important;
    right: 60px !important;
    color: #fff !important;
    z-index: 999 !important;
    background-color: #1c1c1b !important;
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }

  .slick-next {
    top: -28px !important;
    bottom: -20px !important;
    left: auto !important;
    right: 5px !important;
    color: #fff !important;
    background-color: #161616 !important;
    z-index: 999 !important;
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 0.75;
    color: #f5c843;
    margin-top: -17px;
  }
}

.albumslickbottomslider {
  .slick-initialized .slick-slide.slick-current {
    .active {
      transform: scale(1.15);
      transition: all 0.4s ease-in;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }

  .slick-track {
    padding: 60px 0;
  }

  .slick-prev:before {
    content: "‹";
  }

  .slick-next:before {
    content: "›";
  }

  .slick-prev {
    left: 47% !important;
    top: 100% !important;
    // / bottom: 0% !important; /
    right: 60px !important;
    color: #fff !important;
    background-color: #e7ddce !important;
    border: 2px solid #323232;
    z-index: 999 !important;
    position: absolute;

    &:hover {
      background: #000;
      border: 2px solid rgb(243, 23, 51);
    }

    &.slick-disabled {
      opacity: 0.5;
    }
  }

  .slick-next {
    position: absolute;
    left: 50%;
    top: 100% !important;
    right: 5px !important;
    color: #fff !important;
    background-color: #e7ddce !important;
    border: 2px solid #323232;
    z-index: 999 !important;

    &:hover {
      background: #000;
      border: 2px solid rgb(243, 23, 51);
    }

    &.slick-disabled {
      opacity: 0.5;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    color: #323232;
    margin-top: -17px;
  }
}

.increaseDecreaseButton {
  background-color: #323232;
  border-radius: 10px;
}

.albumslickbottomslider .slick-track {
  padding: 30px 0 60px !important;
}

.slickbottomslider .slick-track {
  padding: 0px 0 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

// .video-responsive {
//   overflow: hidden;
//   padding-bottom: 56.25%;
//   position: relative;
//   height: 0;
// }

// .video-responsive iframe {
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   position: absolute;
// }

///////////mukesh//////////////

.dropzonebox {
  border-radius: 5px;
  margin-top: 30px;
  @media screen and (max-width: 767px) {
    padding: 30px 9px 20px 9px;
  }
  @media screen and (max-width: 414px) {
    padding: 20px 9px 20px 9px;
  }
  .boxImage {
    position: relative;
    display: inline-block;
    width: 200px;
    border-radius: 100%;
    background-color: #1a1a1a;
    border: 2px dashed rgba(255, 255, 255, 0.25);
    span {
      position: absolute;
      top: 40%;
      right: 40%;
      background-color: #1a1a1a;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 414px) {
        width: 37px;
        height: 37px;
        bottom: 17px;
      }
    }
    img {
      max-width: 200px;
      max-height: 200px;
      min-width: 200px;
      min-height: 200px;
      overflow: hidden;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
    }
    @media screen and (max-width: 414px) {
      width: 200px;
      height: 200px;
    }
  }
  .boxImage1 {
    position: relative;
    display: inline-block;
    width: 200px;
    border-radius: 100%;
    background-color: #1a1a1a;
    border: 2px dashed rgba(255, 255, 255, 0.25);
    span {
      position: absolute;
      top: 40%;
      right: 40%;
      background-color: #1a1a1a;
      width: 50px;
      height: 50px;
      z-index: -1;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 414px) {
        width: 37px;
        height: 37px;
        bottom: 17px;
      }
    }
    img {
      max-width: 200px;
      max-height: 200px;
      min-width: 200px;
      min-height: 200px;
      overflow: hidden;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;

      @media screen and (max-width: 600px) {
        min-width: 200px;
        min-height: 200px;
      }
    }
    @media screen and (max-width: 414px) {
      width: 200px;
      height: 200px;
    }
  }

  button {
    width: 184px;
  }
}
.dropzoneboxcover {
  border-radius: 5px;
  margin-top: 90px;
  @media screen and (max-width: 767px) {
    padding: 30px 9px 20px 9px;
  }
  @media screen and (max-width: 414px) {
    padding: 20px 9px 20px 9px;
  }
  .boxImage {
    position: relative;
    display: inline-block;
    width: 350px;
    border-radius: 10px;
    background-color: #1a1a1a;
    border: 2px dashed rgba(255, 255, 255, 0.25);
    span {
      position: absolute;
      top: 40%;
      right: 40%;
      background-color: #1a1a1a;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 414px) {
        width: 37px;
        height: 37px;
        bottom: 17px;
      }
    }
    img {
      max-width: 350;
      max-height: 160px;
      min-width: 350;
      min-height: 160px;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
    }
    @media screen and (max-width: 414px) {
      width: 330px;
      height: 140px;
    }
  }
  .boxImage1 {
    position: relative;
    display: inline-block;
    width: 350px;
    border-radius: 10px;
    background-color: #1a1a1a;
    border: 2px dashed rgba(255, 255, 255, 0.25);
    span {
      position: absolute;
      top: 40%;
      right: 40%;
      background-color: #1a1a1a;
      width: 50px;
      height: 50px;
      z-index: -1;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 414px) {
        width: 37px;
        height: 37px;
        bottom: 17px;
      }
    }
    img {
      max-width: 350;
      max-height: 160px;
      min-width: 350;
      min-height: 160px;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      @media screen and (max-width: 600px) {
        min-width: 330px;
        min-height: 140px;
      }
    }
    @media screen and (max-width: 414px) {
      width: 330px;
      height: 140px;
    }
  }

  button {
    width: 184px;
  }
}
.dropzoneboxcoverdetails {
  border-radius: 5px;
  margin-top: 40px;
  @media screen and (max-width: 767px) {
    padding: 30px 9px 20px 9px;
  }
  @media screen and (max-width: 414px) {
    padding: 20px 9px 20px 9px;
  }
  .boxImage {
    position: relative;
    display: inline-block;
    max-width: 400px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px dashed rgba(255, 255, 255, 0.25);
    span {
      position: absolute;
      top: 34%;
      right: 45%;
      background-color: transparent;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 414px) {
        width: 37px;
        height: 37px;
        bottom: 17px;
      }
    }
    img {
      max-width: 350;
      max-height: 160px;
      // min-width: 350;
      min-height: 160px;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
    }
    @media screen and (max-width: 414px) {
      // width: 330px;
      height: 140px;
    }
  }
  .boxImage1 {
    position: relative;
    display: inline-block;
    width: 350px;
    border-radius: 10px;
    background-color: #1a1a1a;
    border: 2px dashed rgba(255, 255, 255, 0.25);
    span {
      position: absolute;
      top: 40%;
      right: 40%;
      background-color: #1a1a1a;
      width: 50px;
      height: 50px;
      z-index: -1;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 414px) {
        width: 37px;
        height: 37px;
        bottom: 17px;
      }
    }
    img {
      max-width: 350;
      max-height: 160px;
      min-width: 350;
      min-height: 160px;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      @media screen and (max-width: 600px) {
        min-width: 330px;
        min-height: 140px;
      }
    }
    @media screen and (max-width: 414px) {
      width: 330px;
      height: 140px;
    }
  }
  button {
    width: 184px;
  }
}

.imageBox {
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 2px solid #f5c843d9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    width: 100%;
    min-height: 100%;
  }
}
.tickmark {
  width: 70px;
  border: 1.5px solid rgba(255, 255, 255, 0.03);
  height: 42px;
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
}
.BoxFile {
  border: 2px dashed rgba(255, 255, 255, 0.25);
  cursor: pointer;
  height: 170px;
  padding: 10px;
  max-width: 450px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  svg {
    color: rgba(255, 255, 255, 0.6);
    font-size: 45px;
    margin-top: 33px;
  }
}
.TabBox {
  .buttonBoxtab {
    button {
      color: rgba(255, 255, 255, 0.6);
      width: 80%;
      cursor: pointer;
      display: flex;
      padding: 0px 0px 16px;
      font-size: 16px;
      align-items: center;
      font-family: "Inter", sans-serif;
      font-weight: 300;
      white-space: pre;
      text-decoration: none;
      @media (max-width: 767px) {
        font-size: 14px;
      }
      &.active {
        color: rgba(255, 255, 255, 0.6);
        font-family: "Inter", sans-serif;
        border-bottom: 3px solid #f5c843;
      }
    }
  }
}

.displaySpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoImg img {
  width: 100%;
}
