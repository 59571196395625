@charset "UTF-8";
body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #ffffff;
  color: #000000;
  font-family: "Inter", sans-serif;
}

label {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

iframe {
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.yellowShade {
  position: absolute;
  width: 900px;
  height: 900px;
  left: 50%;
  top: -50%;
  transform: translateX(-50%);
  background: radial-gradient(29.3% 29.3% at 55.72% 49.12%, #f5c843 0%, rgba(245, 200, 67, 0) 100%);
  opacity: 0.45;
  filter: blur(28px);
  max-width: 100%;
}

.blueShade {
  position: absolute;
  width: 900px;
  height: 900px;
  left: -20px;
  top: 15vh;
  background: radial-gradient(54.45% 64.16% at 38.21% 51.43%, #147ec6 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.75;
  border-radius: 50%;
  filter: blur(148px);
  max-width: 100%;
}

.increaseDecreaseButton {
  background-color: #323232;
  border-radius: 10px;
}

.tableCellContent {
  background: rgba(255, 255, 255, 0.03);
  padding: 8px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.slickbottomslider .slick-initialized .slick-slide.slick-current .active {
  transform: scale(1.15);
  transition: all 0.4s ease-in;
}
.slickbottomslider .slick-next:before,
.slickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -17px !important;
}
.slickbottomslider .slick-track {
  padding: 60px 0;
}
.slickbottomslider .slick-prev:before {
  content: "‹";
}
.slickbottomslider .slick-next:before {
  content: "›";
}
.slickbottomslider .slick-prev {
  left: auto !important;
  bottom: -20px !important;
  top: -28px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: #1c1c1b !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.slickbottomslider .slick-next {
  top: -28px !important;
  bottom: -20px !important;
  left: auto !important;
  right: 5px !important;
  color: #fff !important;
  background-color: #161616 !important;
  z-index: 999 !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.slickbottomslider .slick-next:before,
.slickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: #f5c843;
  margin-top: -17px;
}

.albumslickbottomslider .slick-initialized .slick-slide.slick-current .active {
  transform: scale(1.15);
  transition: all 0.4s ease-in;
}
.albumslickbottomslider .slick-next:before,
.albumslickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -17px !important;
}
.albumslickbottomslider .slick-track {
  padding: 60px 0;
}
.albumslickbottomslider .slick-prev:before {
  content: "‹";
}
.albumslickbottomslider .slick-next:before {
  content: "›";
}
.albumslickbottomslider .slick-prev {
  left: 47% !important;
  top: 100% !important;
  right: 60px !important;
  color: #fff !important;
  background-color: #e7ddce !important;
  border: 2px solid #323232;
  z-index: 999 !important;
  position: absolute;
}
.albumslickbottomslider .slick-prev:hover {
  background: #000;
  border: 2px solid rgb(243, 23, 51);
}
.albumslickbottomslider .slick-prev.slick-disabled {
  opacity: 0.5;
}
.albumslickbottomslider .slick-next {
  position: absolute;
  left: 50%;
  top: 100% !important;
  right: 5px !important;
  color: #fff !important;
  background-color: #e7ddce !important;
  border: 2px solid #323232;
  z-index: 999 !important;
}
.albumslickbottomslider .slick-next:hover {
  background: #000;
  border: 2px solid rgb(243, 23, 51);
}
.albumslickbottomslider .slick-next.slick-disabled {
  opacity: 0.5;
}
.albumslickbottomslider .slick-next:before,
.albumslickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  color: #323232;
  margin-top: -17px;
}

.increaseDecreaseButton {
  background-color: #323232;
  border-radius: 10px;
}

.albumslickbottomslider .slick-track {
  padding: 30px 0 60px !important;
}

.slickbottomslider .slick-track {
  padding: 0px 0 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

.dropzonebox {
  border-radius: 5px;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .dropzonebox {
    padding: 30px 9px 20px 9px;
  }
}
@media screen and (max-width: 414px) {
  .dropzonebox {
    padding: 20px 9px 20px 9px;
  }
}
.dropzonebox .boxImage {
  position: relative;
  display: inline-block;
  width: 200px;
  border-radius: 100%;
  background-color: #1a1a1a;
  border: 2px dashed rgba(255, 255, 255, 0.25);
}
.dropzonebox .boxImage span {
  position: absolute;
  top: 40%;
  right: 40%;
  background-color: #1a1a1a;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzonebox .boxImage img {
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage {
    width: 200px;
    height: 200px;
  }
}
.dropzonebox .boxImage1 {
  position: relative;
  display: inline-block;
  width: 200px;
  border-radius: 100%;
  background-color: #1a1a1a;
  border: 2px dashed rgba(255, 255, 255, 0.25);
}
.dropzonebox .boxImage1 span {
  position: absolute;
  top: 40%;
  right: 40%;
  background-color: #1a1a1a;
  width: 50px;
  height: 50px;
  z-index: -1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage1 span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzonebox .boxImage1 img {
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 600px) {
  .dropzonebox .boxImage1 img {
    min-width: 200px;
    min-height: 200px;
  }
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage1 {
    width: 200px;
    height: 200px;
  }
}
.dropzonebox button {
  width: 184px;
}

.dropzoneboxcover {
  border-radius: 5px;
  margin-top: 90px;
}
@media screen and (max-width: 767px) {
  .dropzoneboxcover {
    padding: 30px 9px 20px 9px;
  }
}
@media screen and (max-width: 414px) {
  .dropzoneboxcover {
    padding: 20px 9px 20px 9px;
  }
}
.dropzoneboxcover .boxImage {
  position: relative;
  display: inline-block;
  width: 350px;
  border-radius: 10px;
  background-color: #1a1a1a;
  border: 2px dashed rgba(255, 255, 255, 0.25);
}
.dropzoneboxcover .boxImage span {
  position: absolute;
  top: 40%;
  right: 40%;
  background-color: #1a1a1a;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzoneboxcover .boxImage span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzoneboxcover .boxImage img {
  max-width: 350;
  max-height: 160px;
  min-width: 350;
  min-height: 160px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 414px) {
  .dropzoneboxcover .boxImage {
    width: 330px;
    height: 140px;
  }
}
.dropzoneboxcover .boxImage1 {
  position: relative;
  display: inline-block;
  width: 350px;
  border-radius: 10px;
  background-color: #1a1a1a;
  border: 2px dashed rgba(255, 255, 255, 0.25);
}
.dropzoneboxcover .boxImage1 span {
  position: absolute;
  top: 40%;
  right: 40%;
  background-color: #1a1a1a;
  width: 50px;
  height: 50px;
  z-index: -1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzoneboxcover .boxImage1 span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzoneboxcover .boxImage1 img {
  max-width: 350;
  max-height: 160px;
  min-width: 350;
  min-height: 160px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 600px) {
  .dropzoneboxcover .boxImage1 img {
    min-width: 330px;
    min-height: 140px;
  }
}
@media screen and (max-width: 414px) {
  .dropzoneboxcover .boxImage1 {
    width: 330px;
    height: 140px;
  }
}
.dropzoneboxcover button {
  width: 184px;
}

.dropzoneboxcoverdetails {
  border-radius: 5px;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .dropzoneboxcoverdetails {
    padding: 30px 9px 20px 9px;
  }
}
@media screen and (max-width: 414px) {
  .dropzoneboxcoverdetails {
    padding: 20px 9px 20px 9px;
  }
}
.dropzoneboxcoverdetails .boxImage {
  position: relative;
  display: inline-block;
  max-width: 400px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px dashed rgba(255, 255, 255, 0.25);
}
.dropzoneboxcoverdetails .boxImage span {
  position: absolute;
  top: 34%;
  right: 45%;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzoneboxcoverdetails .boxImage span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzoneboxcoverdetails .boxImage img {
  max-width: 350;
  max-height: 160px;
  min-height: 160px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 414px) {
  .dropzoneboxcoverdetails .boxImage {
    height: 140px;
  }
}
.dropzoneboxcoverdetails .boxImage1 {
  position: relative;
  display: inline-block;
  width: 350px;
  border-radius: 10px;
  background-color: #1a1a1a;
  border: 2px dashed rgba(255, 255, 255, 0.25);
}
.dropzoneboxcoverdetails .boxImage1 span {
  position: absolute;
  top: 40%;
  right: 40%;
  background-color: #1a1a1a;
  width: 50px;
  height: 50px;
  z-index: -1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzoneboxcoverdetails .boxImage1 span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzoneboxcoverdetails .boxImage1 img {
  max-width: 350;
  max-height: 160px;
  min-width: 350;
  min-height: 160px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 600px) {
  .dropzoneboxcoverdetails .boxImage1 img {
    min-width: 330px;
    min-height: 140px;
  }
}
@media screen and (max-width: 414px) {
  .dropzoneboxcoverdetails .boxImage1 {
    width: 330px;
    height: 140px;
  }
}
.dropzoneboxcoverdetails button {
  width: 184px;
}

.imageBox {
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 2px solid rgba(245, 200, 67, 0.8509803922);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.imageBox img {
  width: 100%;
  min-height: 100%;
}

.tickmark {
  width: 70px;
  border: 1.5px solid rgba(255, 255, 255, 0.03);
  height: 42px;
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
}

.BoxFile {
  border: 2px dashed rgba(255, 255, 255, 0.25);
  cursor: pointer;
  height: 170px;
  padding: 10px;
  max-width: 450px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}
.BoxFile svg {
  color: rgba(255, 255, 255, 0.6);
  font-size: 45px;
  margin-top: 33px;
}

.TabBox .buttonBoxtab button {
  color: rgba(255, 255, 255, 0.6);
  width: 80%;
  cursor: pointer;
  display: flex;
  padding: 0px 0px 16px;
  font-size: 16px;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  white-space: pre;
  text-decoration: none;
}
@media (max-width: 767px) {
  .TabBox .buttonBoxtab button {
    font-size: 14px;
  }
}
.TabBox .buttonBoxtab button.active {
  color: rgba(255, 255, 255, 0.6);
  font-family: "Inter", sans-serif;
  border-bottom: 3px solid #f5c843;
}

.displaySpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoImg img {
  width: 100%;
}

@media (max-width: 1440px) {
  .albumslickbottomslider .slick-next {
    left: 51% !important;
  }
}
@media (max-width: 1280px) {
  .albumslickbottomslider .slick-next {
    left: 52% !important;
  }
}
@media (max-width: 991px) {
  .albumslickbottomslider .slick-next {
    left: 55% !important;
  }
  .slick-next {
    left: 52% !important;
  }
  .circleBox {
    width: 500px;
    height: 500px;
  }
  .circleBoxNew {
    width: 500px;
    height: 500px;
  }
  .squarecontentBox {
    width: 40px !important;
  }
}
@media (max-width: 767px) {
  .gradientButton {
    padding: 7px 21px !important;
    height: 43px !important;
  }
  .graytButton {
    padding: 7px 21px !important;
    height: 43px !important;
  }
  .albumslickbottomslider .slick-prev {
    left: 45% !important;
  }
  .circleBox {
    width: 330px;
    height: 330px;
  }
  .circleBoxNew {
    width: 330px;
    height: 330px;
  }
  .squarecontentBox {
    width: 78px !important;
  }
  .filterBtn {
    font-size: 10px !important;
    padding: 6px 15px !important;
  }
  .slick-next {
    left: 52% !important;
  }
  .CreaterfilterBtn {
    min-width: 0px !important;
  }
}/*# sourceMappingURL=main.css.map */